footer {
  height: 40rem;
  padding-bottom: 10rem;
  margin-top: 25rem;
  .logo {
    margin: 4rem;
    text-align: center;
    height: 12.8rem;
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

.social_links {
  text-align: center;
  padding-top: 3rem;
  a {
    padding: 0 1rem;
  }
  img {
    opacity: 0.8;
    height: 4rem !important;
    width: 4rem !important;
  }
}
