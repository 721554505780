.column{
  text-align: left;
  padding-bottom: 200px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;


  .info {
    width: 33.33%;
    min-width: 30rem;
    padding-right: 2rem;

    .icon{
      width: 100%;
      max-width: 120px;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  p {
    color: #999999;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  .images{
    width: 100%;
    max-width: 840px;
    margin-left: 20px;
    height: 840px;

    img{
      width: 100%;
    }



  }

  .project-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
  }

  ul{
    li strong {
      color: #202020;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
    }
  }

  li {
    margin-bottom: 5px;
    color: #999999;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
  }
  .divider {
    width: 50%;
    height: .1rem;
    background-color: #CCCCCC;
    margin: 3rem 0;
  }

  .preview1{
    z-index: 4;
    position: absolute;
  }
  .preview2{
    z-index: 3;
    position: absolute;
  }
  .preview3{
    z-index: 2;
    position: absolute;
  }
}
@media (max-width: $tablet){
  .column{
    .info {
      min-width: 25rem;
    }
  }
}

@media (max-width: $mobile){

  .column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding-bottom: 10rem;

    .info {
      width: 100%;
      max-width: 48rem;
    }
  }



  .preview1{
    display: none;
  }

  .preview2{
    display: none;
  }
  .preview3{
    display: none;
  }
}

.disabled{
  a {
    pointer-events: none;
    cursor: default;
  }
}
.appstore {
  cursor: pointer;
}