@font-face{
  font-family: proximaNova;
  src: url(./fonts/proximaNova.woff2);
  font-weight: 300;
}@font-face{
  font-family: proximaNovaSemibold;
  src: url(./fonts/proximaNovaSemibold.woff2);
  font-weight: 700;
}
$font-stack: helvetica,arial,sans-serif;;
$primary-color: #6deeb9;
$secondary-color: #2f3a49;
$desk: 1280px;
$tablet: 1024px;
$mobile: 800px;
$shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
