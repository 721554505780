@import "./components/header";
@import "./components/footer";
@import "./vairables";
@import "./components/projectShowcase";

* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  list-style: none !important;
  outline: none !important;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: $secondary-color;
  font-family: $font-stack;
  font-size: 1.6rem;
  font-weight: 400;
}

h1 {
  color: #202020;
  font-family: "proximaNovaSemibold", Helvetica, sans-serif;
  line-height: 5.9rem;
  font-size: 4.8rem;
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.current-projects-title{
  text-align: left;
  margin-bottom: 2rem;
  border-left: 4px solid #70ffd9;
  padding: 2rem 0 2rem 1.2rem;
}
.past-projects-title{
  text-align: left;
  margin-bottom: 2rem;
  border-left: 4px solid #70ffd9;
  padding: 2rem 0 2rem 1.2rem;
}
h2 {
  color: #202020;
  font-family: "proximaNovaSemibold", Helvetica, sans-serif;
  text-align: left;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 5px;
}
h3 {
  text-align: left;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.6rem;
  margin-bottom: 0.5rem;
}
a {
  color: $primary-color;
}
li {
  list-style: none;
}
button {
  box-shadow: $shadow;
  padding: 1rem 2rem;
  border-radius: 5rem;
  font-weight: bold;
  font-size: 1.6rem;
  height: 4rem;
  display: inline-block;
  position: relative;
  margin: 3rem 1rem 0.25em;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0.08em;
  outline: 0;
  text-shadow: none;
  transition: all 200ms ease;
  -webkit-appearance: button;
  cursor: pointer;
  background: #2f3a49;
  border: solid #71ffd9;
  color: #71ffd9;
}
button:hover {
  padding: 1rem 3rem;
  background: #2f3a49;
  border-color: #71ffd9;
  color: #71ffd9;
}

button:active {
  background: #2f3a49;
  border-color: #71ffd9;
  color: #71ffd9;
}
button.disabled {
  background: #2f3a49;
  border-color: #2f3a49;
  box-shadow: none;
  cursor: auto;
  color: #fff !important;
}
button.disabled:hover {
  padding: 1rem 2rem;
  cursor: default;
}

button.secondary{
  background: none;
  border-color: $secondary-color;
  color: $secondary-color;
  box-shadow: none;
  a{
    color: $secondary-color;
  }
}

button.secondary:hover{
  box-shadow: $shadow;
  padding: 1rem 2rem;
}
section {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

parallax {
  position: absolute;
}

.container {
  position: static;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.title {
  color: $secondary-color;
}
.one-page-section {
  padding: 10rem 0;
}
.one-page-section-dark {
  padding: 10rem 0;
  background: #263033;
  color: $primary-color;

  .title {
    color: $primary-color;
  }
}
.layout {
  max-width: 144rem;
  padding: 0 8rem;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
@media (max-width: $desk) {
  .layout {
    padding: 0 4rem;
  }
}
@media (max-width: $tablet) {
  h2 {
    font-size: 36px;
    line-height: 44px;
  }
  h3 {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}
@media (max-width: $mobile) {
  .layout {
    padding: 0 4rem;
  }
  h3 {
    font-size: 3.6rem;
    line-height: 4.4rem;
    padding-top: 4rem;
  }
  h2 {
    font-size: 36px;
    line-height: 44px;
    padding-top: 20px;
  }

  .current-projects-title{
    font-size: 3rem;
    padding: 1.5rem 0 1.5rem 1.2rem;
  }
  .past-projects-title{
    font-size: 3rem;
    padding: 1.5rem 0 1.5rem 1.2rem;
  }
}
@media (max-width: 680px) {
  h3 {
    font-size: 2.2rem;
    line-height: 2.8rem;
    padding-top: 2rem;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
    padding-top: 20px;
  }
}
