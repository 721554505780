@import "../vairables";
.header{
  height: 105vh;
  width: 100%;
  position: relative;

  .container{
    position: static;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .middle{
    z-index: 9;
  }
}

section#home{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.middle {
  text-align: left;
  //padding-top: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 840px;
  height: 100vh;
}
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.home-s-btn {
    display: block;
    bottom: 50px;
    height: 40px;
    width: 26px;
    border: 2px solid $primary-color;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
}
.hor-center {
    z-index: 1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.home-s-btn .dot {
    display: block;
    height: 6px;
    width: 6px;
    background-color: $primary-color;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: dot-animation 1.8s infinite ease-in-out;
    -moz-animation: dot-animation 1.8s infinite ease-in-out;
    animation: dot-animation 1.8s infinite ease-in-out;
}

section h1 {
  color: $secondary-color;
  text-align: left;
  font-weight: 700;
  font-size: 6.4rem;
  line-height: 7.6rem;
}

section .scroll{
  max-width: 28px;
  width: 100%;
  margin: 10vh auto;
  padding: 20px 0;
}

@media (max-width: $tablet){
  section.middle h1 {
    font-size: 4.8rem;
    line-height: 5.6rem;
  }

  section.middle {
    max-width: 64rem;
  }
}

@media (max-width: $mobile){
  section.middle h1 {
    font-size: 4.8rem;
    line-height: 5.6rem;
  }

  section.middle {
    max-width: 48rem;
    padding-top: 10vh;
  }
}
/*Smooth Scroll Button animation*/
@-webkit-keyframes dot-animation {
	0% {
		top: -webkit-calc(50% - 8px);
		top: calc(50% - 8px);
		opacity: 1;
	}
	40% {
		top: 50%;
		opacity: 0;
	}
	100% {
		top: -webkit-calc(50% - 8px);
		top: calc(50% - 8px);
		opacity: 0;
	}
}
@-moz-keyframes dot-animation {
	0% {
		top: -moz-calc(50% - 6px);
		top: calc(50% - 6px);
		opacity: 1;
	}
	40% {
		top: 50%;
		opacity: 0;
	}
	100% {
		top: -moz-calc(50% - 6px);
		top: calc(50% - 6px);
		opacity: 0;
	}
}
@keyframes dot-animation {
	0% {
		top: -webkit-calc(50% - 10px);
		top: -moz-calc(50% - 10px);
		top: calc(50% - 10px);
		opacity: 1;
	}
	40% {
		top: 80%;
		opacity: .1;
	}
	100% {
		top: -webkit-calc(100% + 6px);
		top: -moz-calc(100% + 6px);
		top: calc(100% + 6px);
		opacity: 0;
	}
}
